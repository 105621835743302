import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import { SprkTable } from "@sparkdesignsystem/spark-react";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Getting Started with Spark Fonts`}</h1>
    <p>{`As part of Spark, our design system includes functionality
for applying web fonts. It allows you to configure your font
setup using Sass variables which will be included in the
Spark CSS bundle when you build your site.`}</p>
    <h2>{`Configuring Sass Variables`}</h2>
    <p>{`There are unique Sass variables that allow you to
configure Spark fonts for your build. Refer to the
descriptions to learn what they are.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` They must be set in your Sass before importing Spark.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sass Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$sprk-using-webfonts`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs Spark that webfonts will be applied. `}<strong parentName="td">{`Note:`}</strong>{` Change default setting from `}<inlineCode parentName="td">{`false`}</inlineCode>{` to `}<inlineCode parentName="td">{`true`}</inlineCode>{` to use Spark webfonts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$sprk-font-display`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`(Optional)`}</strong>{` string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls behavior of the text while fonts are being loaded. `}<strong parentName="td">{`Note:`}</strong>{` Default value is `}<inlineCode parentName="td">{`swap`}</inlineCode>{` which will cause text to render using a fallback font until the custom font is available. Correct font is `}<inlineCode parentName="td">{`swap`}</inlineCode>{`ped in. Information on the `}<inlineCode parentName="td">{`font-display`}</inlineCode>{` property and values are found `}<a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display"
            }}>{`here`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$sprk-webfonts`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sass List`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Expects a Sass List containing one or more nested Sass Lists. `}<strong parentName="td">{`Note:`}</strong>{` The nested lists should contain the font’s URL, name and weight. It does require a series of font declarations.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`A full valid `}<inlineCode parentName="p">{`$sprk-webfonts`}</inlineCode>{` object example looks something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`$sprk-using-webfonts: true;
$sprk-webfonts:
(
  (
    url('https://www.example.com/MyFont-Bold.woff2') format('woff2'),
    url('https://www.example.com/MyFont-Bold.woff') format('woff')
  ),
  MyFont, 700
),
(
  (
    url('https://www.example.com/MyFont-Medium.woff2') format('woff2'),
    url('https://www.example.com/MyFont-Medium.woff') format('woff')
  ),
  MyFont, 500
),
(
  (
    url('https://www.example.com/MyFont-Regular.woff2') format('woff2'),
    url('https://www.example.com/MyFont-Regular.woff') format('woff')
  ),
  MyFont, 400
),
(
  (
    url('https://www.example.com/MyFont-Light.woff2') format('woff2'),
    url('https://www.example.com/MyFont-Light.woff') format('woff')
  ),
  MyFont, 300
);
`}</code></pre>
    <h3>{`Additional Examples`}</h3>
    <p>{`Check out these completed font setup examples supported by Spark to learn more:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/blob/main/src/styles/main.scss"
        }}>{`HTML`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-angular/blob/main/src/styles.scss"
        }}>{`Angular`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-react/blob/main/src/styles.scss"
        }}>{`React`}</a></li>
    </ul>
    <p>{`Internal users can access a directory of our CDN resources by clicking
`}<a parentName="p" {...{
        "href": "http://shorty/sparkassetcdn"
      }}>{`this link`}</a>{`.`}</p>
    <h3>{`Troubleshooting`}</h3>
    <ul>
      <li parentName="ul">{`Rocket products must include the font name, `}<strong parentName="li">{`RocketSans`}</strong>{`. Remove all references of `}<strong parentName="li">{`MyFont`}</strong>{`.`}</li>
      <li parentName="ul">{`Include all four font weights - 300, 400, 500, and 700 - within your build for the fonts to work properly.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      